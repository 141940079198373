<template lang="pug">
v-menu(
  v-model="menu"
  :close-on-content-click="false"
  max-width="100%"
)
  template(v-slot:activator="{ on }")
    v-text-field(
      :value="timepicker ? value : formattedDate"
      clearable
      :hide-details="hideDetails"
      :label="label"
      readonly
      :data-test="dataTest"
      @click="showDatepicker()"
      @click:append="showDatepicker()"
      @click:clear="clearDatepicker()"
      :error="error"
      :error-messages="errorMessages"
    )
      template(v-slot:append)
        v-icon(:color="formattedDate ? 'primary' : ''") {{ icon }}
  v-date-picker(
    v-if="!timepicker"
    :value="value"
    :flat="true"
    :min="min"
    @input="$emit('input', $event)"
    @change="datepickerProcess"
  )
  v-time-picker(
    v-else
    format="24hr"
    :value="value"
    @input="$emit('input', $event)"
    @change="datepickerProcess"
    :allowed-minutes="allowedMinutes"
  )
</template>

<script>
import {convertToDefaultDateFormat} from "@/util"

export default {
  props: {
    value: {
      required: true
    },
    label: String,
    convertToDefault: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'mdi-calendar-today'
    },
    timepicker: {
      type: Boolean,
      default: false
    },
    error: Boolean,
    errorMessages: String,
    hideDetails: {
      type: Boolean,
      default: true
    },
    min: String,
    dataTest: String,
    allowedMinutes: {
      type: Function,
      default: m => m % 10 === 0,
    }
  },

  data: () => ({
    menu: false
  }),

  computed: {
    formattedDate() {
      if(!this.value) return
      if(this.convertToDefault) return convertToDefaultDateFormat(this.value)
      return this.value
    },
  },

  methods: {
    onChange() {
      this.$emit('change')
    },

    datepickerProcess() {
      this.menu = false
    },

    clearDatepicker() {
      this.$emit('input', '')
      this.onChange()
    },

    showDatepicker() {
      this.menu = true
    },
  }
}
</script>